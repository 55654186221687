import { createStore } from 'vuex'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

/**
 * Application store
 */
export default createStore({
	state() {
		return {
			searchboxState: false,
			ads: [],
			banners: [],
			categories: [],
			saved: undefined
		}
	},

	getters,
	mutations,
	actions
})
