<template>
	<div :class="classes">
		<slot></slot>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { twMerge } from 'tailwind-merge'


export default defineComponent({
	props: {
		className: {
			type: String,
			required: false
		}
	},
	computed: {
		classes() {
			return twMerge('mx-auto max-w-7xl py-12 sm:px-6 sm:py-32 pt-4 sm:pt-10 lg:px-8', this.className)
		}
	}
})
</script>