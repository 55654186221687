<template>
	<transition name="scroll-top">
		<div class="btb fixed bottom-16 xl:bottom-8 right-10 z-50 bg-sky-900 rounded-xl p-3
		shadow-sm grid place-items-center cursor-pointer transition-all active:scale-90 hover:bg-ch-dark-red-200"
			v-if="buttonShown" @click="backToTop">
			<ArrowUpIcon class="h-6 w-6 text-white" />
		</div>
	</transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ArrowUpIcon } from '@heroicons/vue/24/outline'

/**
 * Back to top button
 */
export default defineComponent({
	components: { 
		ArrowUpIcon
	},
	data() {
		return {
			scrollTimer: 0 as any,
			scrollY: 0
		}
	},
	computed: {
		buttonShown(): boolean {
			return this.scrollY > 200
		}
	},
	methods: {
		handleScroll() {
			if (this.scrollTimer) return

			this.scrollTimer = setTimeout(() => {
				this.scrollY = window.scrollY
				clearTimeout(this.scrollTimer)
				this.scrollTimer = 0
			}, 100)
		},
		backToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	}
})
</script>

<style scoped>
.animated {
	animation: float 300ms forwards;
}

@keyframes float {
	0% {
		right: -5rem;
	}

	100% {
		right: 2.5rem;
	}
}

.scroll-top-enter-active,
.scroll-top-leave-active {
	transition: all 200ms;
}

.scroll-top-enter-to {
	opacity: 1;
	right: 2.5rem;
}

.scroll-top-enter-from {
	opacity: 0;
	right: -2.5rem;
}

.scroll-top-leave-to {
	opacity: 0;
	right: -2.5rem;
}

.scroll-top-leave-from {
	opacity: 1;
	right: 2.5rem;
}
</style>