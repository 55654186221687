<template>
	<TransitionRoot :show="menuState" as="template" :appear="true">
		<Dialog as="div" class="lg:hidden fixed overflow-x-auto z-50" @close="closeMenu()">
			<div class="fixed inset-0 z-50" />
			
			<TransitionChild
					as="template"
					enter="duration-150 transition transform"
					enter-from="translate-x-full"
					enter-to="translate-x-0"
					leave="duration-150"
					leave-from="translate-x-0"
					leave-to="translate-x-full"
			>
				<DialogPanel
					class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-[20rem] sm:ring-1 sm:ring-gray-900/10">
					<div class="flex justify-between">
						<RouterLink :to="{ name: 'home' }" class="-mt-4">
							<SimpleHeaderLogo class="w-6" />
						</RouterLink>
						<button type="button" class="-mr-2.5 -mt-3.5 rounded-md p-2.5 text-gray-700" @click="closeMenu()">
							<span class="sr-only">Close menu</span>
							<XMarkIcon class="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					<div class="mt-6 flow-root">
						<div class="-my-6 divide-y divide-gray-500/10">
							<div class="space-y-2 py-6">
								<router-link @click="closeMenu()" v-for="item in navigation" :key="item.name" :to="item.href"
									class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 text-right">{{
										item.name }}</router-link>
								<a role="button" @click="openSearchBox"
									class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 text-right cursor-pointer">Keresés</a>
							</div>
						</div>
					</div>
				</DialogPanel>
			</TransitionChild>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'

import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import SimpleHeaderLogo from '@/components/svg/SimpleHeaderLogo.vue'

/**
 * Navigation prop type
 */
type Navigation = {
	name: string,
	href: {
		name: string
	}
}[]

/**
 * Mobile menu component
 */
export default defineComponent({
	components: {
		Dialog, DialogPanel, XMarkIcon, SimpleHeaderLogo, TransitionRoot, TransitionChild
	},
	emits: ['close', 'open-search-box'],
	props: {
		/**
		 * Navigation items
		 */
		navigation: {
			type: Object as PropType<Navigation>,
			required: true
		},
		/**
		 * Mobile menu state (is open or closed)
		 * @values true, false
		 */
		menuState: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		/**
		 * Close mobile menu
		 */
		closeMenu(): void {
			this.$emit('close')
		},
		/**
		 * Open search box
		 */
		openSearchBox(): void {
			this.$emit('open-search-box')
		}
	}
})
</script>