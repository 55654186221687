<template>
	<div>
		<section id="faq" aria-labelledby="faq-title" class="relative overflow-hidden pt-0 sm:py-32 sm:pt-12">
			<div class="img absolute left-1/2 top-0 max-w-none "  unoptimized></div>
			<div class="relative mx-auto max-w-7xl px-4 lg:px-0">
				<div class="mx-auto max-w-2xl lg:mx-0 mb-16">
					<h2 id="faq-title" class="font-bold text-3xl tracking-tight text-slate-900 sm:text-3xl">Kiemelt hirdetéseink</h2>
					<p class="mt-4 text-lg tracking-tight text-slate-700">Ebben a menüpontban találhatóak a legnépszerűbb hirdetéseink.</p>
				</div>
				<ul class="grid grid-cols-1 xl:grid-cols-2 gap-5">
					<template v-if="postsLoaded">
						<ContactItem v-for="post in posts" :key="post.id" :post="post" />
					</template>
					<template v-else>
						<ContactItemSkeletonLoader />
						<ContactItemSkeletonLoader />
					</template>
				</ul>
			</div>
		</section>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ContactItem from '@/components/layout/ContactItem.vue'
import type { Post } from '@/types/Post'
import type ApiResponse from '@/types/ApiResponse'
import ContactItemSkeletonLoader from '@/components/skeleton-loader/ContactItemSkeletonLoader.vue'


export default defineComponent({
	components: {
		ContactItem, ContactItemSkeletonLoader
	},
	data() {
		return {
			posts: undefined as Post[] | undefined,
		}
	},
	computed: {
		postsLoaded(): boolean {
			return this.posts !== undefined
		}
	},
	methods: {
		async loadPosts() {
			try {
				const response: ApiResponse<Post> = await this.$store.dispatch('loadHighlightedPosts')

				if (response.status !== 200)
					throw new Error('Error when fetching request ' + response.status)

				this.posts = response.data
			} catch (err) {
				//
			}
		}
	},
	watch: {
		'$store.state.categories'() {
			this.loadPosts()
		}
	},
	created() {
		if (this.$store.getters.categoriesLoaded) {
			this.loadPosts()
		}
	}
})
</script>