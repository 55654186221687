<template>
	<ul :class="classes" role="presentation">
		<template v-if="!adsLoaded">
			<li v-for="i in 3" :key="i">
				<ImageSkeletonLoader class="w-full h-[150px] xl:h-[200px]" />
			</li>
		</template>

		<template v-else>
			<li v-for="ad of ads" :key="ad.id">
				<AdBox :ad="ad" />
			</li>
		</template>
	</ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Ad from '@/types/Ad'
import AdBox from './AdBox.vue'
import ImageSkeletonLoader from '../skeleton-loader/ImageSkeletonLoader.vue'
import { twMerge } from 'tailwind-merge'


export default defineComponent({
	components: {
		AdBox, ImageSkeletonLoader
	},
	data() {
		return {

		}
	},
	computed: {
		ads(): Ad[] {
			return this.$store.state.ads.shuffle()
		},
		adsLoaded(): boolean {
			return this.ads.length > 0
		},
		classes() {
			return twMerge('grid grid-cols-[repeat(auto-fit,150px)] md:grid-cols-1 justify-center', this.classList)
		}
	},
	props: {
		classList: {
			type: String,
			required: false
		}
	}
})
</script>