<template>
	<div v-if="!consent"
		class="fixed z-50 inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
		<p class="max-w-4xl text-sm leading-6 text-gray-900">Az oldalunk sütiket (cookie-kat) használ annak érdekében, hogy
			javítsa a böngészési élményt, személyre szabja a tartalmat, és elemzéseket készítsen a weboldal
			teljesítményéről. További információkat a <router-link :to="{ name: 'cookie-policy' }"
				class="font-semibold text-sky-600">süti tájékoztatóban</router-link> találsz.</p>
		<div class="flex flex-none items-center gap-x-5">
			<button @click="saveConsent('all')" type="button"
				class="rounded-md bg-sky-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-900">Összes
				elfogadása</button>
			<button @click="saveConsent('required')" type="button"
				class="text-sm font-semibold leading-6 text-gray-900">Szükséges sütik elfogadása</button>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

type ConsentType = 'all' | 'required'

/**
 * Cookie Consent
 */
export default defineComponent({
	data() {
		return {
			consent: false
		}
	},
	computed: {
		cookieName(): string {
			return process.env.VUE_APP_COOKIE_CONSENT || 'cookie_consent'
		}
	},
	methods: {
		saveConsent(consent: ConsentType) {
			let cookie = consent === 'all' ?? 'required'

			this.$cookies.set(this.cookieName, cookie, '1y')

			this.consent = true
		}
	},
	created() {
		if (this.$cookies.isKey(this.cookieName)) {
			this.consent = true
		}
	}
})
</script>