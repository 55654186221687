import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes'

/**
 * Router
 */
const router = createRouter({
	history: createWebHistory(),
	scrollBehavior: () => {
		return { top: 0 }
	},
	routes
})

/* router.beforeEach((to, from) => {
	if (to.name === 'category-sitemap') {
		window.location.href = 'https://api.vállalkozások.hu/wp-json/sitemap/categories'
	} else if (to.name === 'post-sitemap') {
		window.location.href = 'https://api.vállalkozások.hu/wp-json/sitemap/posts'
	} else {
		return true
	}

	return false
}) */

export default router
