import { RouteRecordRaw } from "vue-router"

import NotFoundPage from "@/pages/NotFoundPage.vue"
import HomePage from "@/pages/HomePage.vue"


/**
 * Routes
 */
const routes: Array<RouteRecordRaw> = [
	{ path: '/', component: HomePage, name: 'home' },
	{ path: '/kategoriak', component: () => import('@/pages/CategoriesPage.vue'), name: 'categories-all' },

	/* EXCEPTION */
	{ path: '/kategoriak/epitoipar/nyisztor-daru-kft-daru-berles-pecs', redirect: '/kategoriak/epitoipar/nyisztor-daru-kft' },

	{ path: '/kategoriak/:category', component: () => import('@/pages/CategoryPage.vue'), name: 'category' },
	{ path: '/kategoriak/:category/:slug', component: () => import('@/pages/DetailsPage.vue'), name: 'contact-detail'},
	{ path: '/kereses', component: () => import('@/pages/SearchResultsPage.vue'), name: 'search' },
	{ path: '/kapcsolat', component: () => import('@/pages/ContactPage.vue'), name: 'contact' },
	{ path: '/suti-tajekoztato', component: () => import('@/pages/CookiePolicyPage.vue'), name: 'cookie-policy' },
	{ path: '/aszf', component: () => import('@/pages/AszfPage.vue'), name: 'aszf' },
	{ path: '/adatkezelesi-tajekoztato', component: NotFoundPage, name: 'data-policy' }, // TODO

	{
		path: '/categories-sitemap.xml', 
		component: NotFoundPage,
		beforeEnter() {
			window.location.href = 'https://api.xn--vllalkozsok-k7ah.hu/wp-json/sitemap/categories'
		},
		name: 'category-sitemap'
	},
	{
		path: '/posts-sitemap.xml', 
		component: NotFoundPage,
		beforeEnter() {
			window.location.href = 'https://api.xn--vllalkozsok-k7ah.hu/wp-json/sitemap/posts'
		},
		name: 'post-sitemap'
	},

	{ path: '/:pathMatch(.*)*', component: NotFoundPage, name: 'not-found' }
]

export default routes