<template>
	<BaseSkeletonLoader>
		<PhotoIcon class="w-8 h-8 text-gray-400" />
	</BaseSkeletonLoader>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseSkeletonLoader from './BaseSkeletonLoader.vue'
import { PhotoIcon } from '@heroicons/vue/24/outline'

export default defineComponent({
	components: {
		BaseSkeletonLoader, PhotoIcon
	}
})
</script>