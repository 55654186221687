import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.ad.href,
    target: "_blank"
  }, [
    _createElementVNode("img", {
      src: _ctx.ad.imgPath,
      class: _normalizeClass([_ctx.classes, "object-contain bg-gray-300 min-h-[150px] xl:min-h-[180px]"]),
      alt: _ctx.ad.title
    }, null, 10, _hoisted_2)
  ], 8, _hoisted_1))
}