<template>
	<li class="overflow-hidden rounded-lg bg-white shadow-sm border border-gray-200 transition-all">
		<div class="px-4 py-5 sm:p-5">
			<div class="sm:flex relative">
				<div class="mb-4 flex-shrink-0 sm:mb-0 sm:mr-5">
					<ImageSkeletonLoader class="w-36 h-36" />
				</div>
				<div class="w-full">
					<div>
						<BaseSkeletonLoader class="w-3/4 h-7" />
						<BaseSkeletonLoader class="mt-2 w-4/6 h-5" />
						<BaseSkeletonLoader class="mt-1 w-5/6 h-5" />
						<BaseSkeletonLoader class="mt-1 w-3/6 h-5" />
					</div>

					<div class="relative mt-5 mb-3">
						<div class="inset-0 flex items-center" aria-hidden="true">
							<div class="w-full border-t border-gray-300" />
						</div>
					</div>

					<p class="flex text-xs items-center">
						<BaseSkeletonLoader class="w-3/5 h-4" />
					</p>
					<div class="relative mt-3 mb-3">
						<div class="inset-0 flex items-center" aria-hidden="true">
							<div class="w-full border-t border-gray-300" />
						</div>
					</div>

					<p class="flex text-xs items-center">
						<BaseSkeletonLoader class="w-2/5 h-4" />
					</p>
					<div class="relative mt-3 mb-3">
						<div class="inset-0 flex items-center" aria-hidden="true">
							<div class="w-full border-t border-gray-300" />
						</div>
					</div>

					<p class="flex text-xs items-center">
						<BaseSkeletonLoader class="w-3/5 h-4" />
					</p>
					<div class="relative mt-3 mb-3">
						<div class="inset-0 flex items-center" aria-hidden="true">
							<div class="w-full border-t border-gray-300" />
						</div>
					</div>

					<p class="flex text-xs items-center">
						<BaseSkeletonLoader class="w-2/5 h-4" />
					</p>
				</div>
			</div>
		</div>
	</li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseSkeletonLoader from './BaseSkeletonLoader.vue'
import ImageSkeletonLoader from './ImageSkeletonLoader.vue'

export default defineComponent({
	components: {
		BaseSkeletonLoader, ImageSkeletonLoader
	}
})
</script>