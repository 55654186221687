import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhotoIcon = _resolveComponent("PhotoIcon")!
  const _component_BaseSkeletonLoader = _resolveComponent("BaseSkeletonLoader")!

  return (_openBlock(), _createBlock(_component_BaseSkeletonLoader, null, {
    default: _withCtx(() => [
      _createVNode(_component_PhotoIcon, { class: "w-8 h-8 text-gray-400" })
    ]),
    _: 1
  }))
}