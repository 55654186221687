<template>
	<div class="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-16 pb-24 sm:py-40 lg:px-8">
		<p class="text-base font-semibold leading-8 text-sky-600">404</p>
		<h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Az oldal nem található</h1>
		<p class="mt-6 text-base leading-7 text-gray-600">
			Sajnáljuk, de a keresett oldal nem található. Kérjük, ellenőrizze az URL-t, vagy térjen vissza a kezdőoldalra.</p>
		<div class="mt-10">
			<button @click.prevent="goBack()" class="text-base font-semibold leading-7 text-sky-600 hover:text-sky-800 transition-all cursor-pointer mb-1">
				<span aria-hidden="true" class="mr-1">&larr;</span> Vissza</button>
			<router-link :to="{ name: 'home' }" class="text-base font-semibold leading-7 text-sky-600 hover:text-sky-800 transition-all flex items-center">
				<span aria-hidden="true" class="mr-1"><HomeIcon class="w-4 mr-[3px]" /></span> Vissza a kezdőlapra </router-link>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { HomeIcon } from '@heroicons/vue/20/solid';

export default defineComponent({
	components: {
		HomeIcon
	},
	methods: {
		goBack(): void {
			this.$router.go(-1)
		}
	},
	metaInfo() {
		return {
			title: 'A keresett oldal nem található - Vállalkozások.hu',
			meta: [
				{ name: 'description', content: 'Sajnáljuk, de a keresett oldal nem található. Az oldalunkon történő kereséshez használja a menüpontokat vagy térjen vissza a kezdőoldalra. Az általános hibák elkerülése érdekében ellenőrizze a webcímet, vagy használja a keresőt.' }
			]
		}
	},
})
</script>