<template>
	<li class="overflow-hidden transition-all bg-white border border-gray-200 rounded-lg shadow-sm group hover:border-gray-600" :class="{'border-gray-400': post.highlighted}">
		<router-link :to="{name: 'contact-detail', params: { category: post.category.slug, slug: post.slug }}" @click="save">
			<div class="px-4 py-5 sm:p-5">
				<div class="relative sm:flex">
					<kbd v-if="post.highlighted" class="mx-1 flex h-5 w-fit px-1.5 items-center justify-center rounded border bg-white font-semibold sm:mx-2 border-gray-400 
					text-gray-900 absolute top-0 right-0 text-xs">KIEMELT</kbd>
					<div class="flex-shrink-0 mb-4 sm:mb-0 sm:mr-5">
						<img v-if="post.image" :src="post?.image" class="rounded-md w-36" :alt="post.title">
						<div v-else class="grid bg-gray-200 rounded-md w-36 h-36 place-items-center">
							<PhotoIcon class="w-8 h-8 text-gray-400" />
						</div>
					</div>
					<div class="w-full">
						<div>
							<h2 class="text-lg font-bold transition-all cursor-pointer group-hover:text-sky-800" v-html="post.title">
							</h2>
							<p class="mt-1 text-sm" v-html="post.excerpt"></p>
						</div>

						<div class="relative mt-5 mb-3">
							<div class="inset-0 flex items-center" aria-hidden="true">
								<div class="w-full border-t border-gray-300" />
							</div>
						</div>

						<template v-if="post.meta.address">
							<p class="flex items-center text-xs">
								<MapPinIcon class="w-4 h-4 mr-3" />
								<span>{{ post.meta.address }}</span>
							</p>
							<div class="relative mt-3 mb-3">
								<div class="inset-0 flex items-center" aria-hidden="true">
									<div class="w-full border-t border-gray-300" />
								</div>
							</div>
						</template>

						<template v-if="post.meta.phone">
							<p class="flex items-center text-xs">
								<PhoneIcon class="w-4 h-4 mr-3" />
								<span>{{ post.meta.phone }}</span>
							</p>
							<div class="relative mt-3 mb-3">
								<div class="inset-0 flex items-center" aria-hidden="true">
									<div class="w-full border-t border-gray-300" />
								</div>
							</div>
						</template>

						<template v-if="post.meta.email">
							<p class="flex items-center text-xs">
								<EnvelopeIcon class="w-4 h-4 mr-3" />
								<span>{{ post.meta.email }}</span>
							</p>
							<div class="relative mt-3 mb-3">
								<div class="inset-0 flex items-center" aria-hidden="true">
									<div class="w-full border-t border-gray-300" />
								</div>
							</div>
						</template>

						<template v-if="post.meta.website">
							<p class="flex items-center text-xs">
								<LinkIcon class="w-4 h-4 mr-3" />
								<span>
									<a :href="post.meta.website" target="_blank">
										{{ post.meta.website }}
									</a>
								</span>
							</p>
						</template>
					</div>
				</div>
			</div>
		</router-link>
	</li>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { PhoneIcon, EnvelopeIcon, MapPinIcon, LinkIcon } from '@heroicons/vue/24/outline'
import { Post } from '@/types/Post'
import { PhotoIcon } from '@heroicons/vue/24/outline'


export default defineComponent({
	components: { 
		PhoneIcon, MapPinIcon, EnvelopeIcon, LinkIcon, PhotoIcon
	},
	props: {
		post: {
			type: Object as PropType<Post>,
			required: true
		},
		saveEnabled: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	methods: {
		save() {
			if (this.saveEnabled)
				this.$store.commit('savePost', this.post)
		}
	}
})
</script>