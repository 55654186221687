<template>
	<div class="pointer-events-none inset-x-0 bottom-0 sm:flex sm:justify-center sm:pb-5 pt-10">
		<div class="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 rounded-lg sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 w-full">
			<p class="text-sm leading-6 text-white w-full">
				<template v-if="bannersLoaded">
					<a :href="banner?.href" target="_blank">
						<strong class="font-semibold">{{ banner?.title }}</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>
						<span v-html="banner?.excerpt"></span>&nbsp;
						<span aria-hidden="true">&rarr;</span>
					</a>
				</template>
				<template v-else>
					<BaseSkeletonLoader class="w-3/4 h-5 rounded-md" :skeleton="{backgroundClass: 'bg-gray-500', shimmerColor: '#ffffff'}" />
					<BaseSkeletonLoader class="mt-2 w-2/4 h-5 rounded-md" :skeleton="{backgroundClass: 'bg-gray-500', shimmerColor: '#ffffff'}" />
					<BaseSkeletonLoader class="md:hidden mt-2 w-3/5 h-5 rounded-md" :skeleton="{backgroundClass: 'bg-gray-500', shimmerColor: '#ffffff'}" />
					<BaseSkeletonLoader class="sm:hidden mt-2 w-3/4 h-5 rounded-md" :skeleton="{backgroundClass: 'bg-gray-500', shimmerColor: '#ffffff'}" />
					<BaseSkeletonLoader class="sm:hidden mt-2 w-2/4 h-5 rounded-md" :skeleton="{backgroundClass: 'bg-gray-500', shimmerColor: '#ffffff'}" />
					<BaseSkeletonLoader class="xs:hidden mt-2 w-1/4 h-5 rounded-md" :skeleton="{backgroundClass: 'bg-gray-500', shimmerColor: '#ffffff'}" />
				</template>
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Ad from '@/types/Ad'
import BaseSkeletonLoader from '../skeleton-loader/BaseSkeletonLoader.vue'

export default defineComponent({
	components: {
		BaseSkeletonLoader
	},
	data() {
		return {
			banner: undefined as Ad | undefined
		}
	},
	computed: {
		bannersLoaded(): boolean {
			return this.$store.state.banners.length > 0
		}
	},
	methods: {
		selectBanner() {
			const random = Math.floor(Math.random() * this.$store.state.banners.length)
			this.banner = this.$store.state.banners[random]
		}
	},
	watch: {
		'bannersLoaded'() {
			this.selectBanner()
		}
	},
	created() {
		this.selectBanner()
	}
})
</script>