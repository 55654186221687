import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-298b0b9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "pt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BackToTopButton = _resolveComponent("BackToTopButton")!
  const _component_CookieConsent = _resolveComponent("CookieConsent")!
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TheHeader),
      _createElementVNode("main", _hoisted_2, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, {
              name: "router",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: new Date().toString()
                }))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }),
        _createVNode(_component_BackToTopButton),
        _createVNode(_component_CookieConsent),
        _createVNode(_component_SearchBox)
      ]),
      _createVNode(_component_TheFooter)
    ])
  ], 64))
}