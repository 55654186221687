import { State } from 'vue';
import { Post } from '@/types/Post'
import Category from '@/types/Category'
import Ad from '@/types/Ad';

/**
 * Store mutations
 */
export default {
	toggleSearchbox(state: State) {
		state.searchboxState = !state.searchboxState
	},
	closeSearchbox(state: State) {
		state.searchboxState = false
	},
	setAds(state: State, ads: Ad[]) {
		state.ads = ads
	},
	setBanners(state: State, banners: Ad[]) {
		state.banners = banners
	},
	setCategories(state: State, categories: Category[]) {
		state.categories = categories
	},
	savePost(state: State, post: Post) {
		state.saved = post
	}
}