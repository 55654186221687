<template>
	<footer class="bg-gray-900 z-10 relative" aria-labelledby="footer-heading">
		<h2 id="footer-heading" class="sr-only">
			Footer
		</h2>
		<div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
			<div class="xl:grid xl:grid-cols-3 xl:gap-8">
				<div class="space-y-8">
					<FooterLogo class="w-52" />

					<p class="text-sm leading-6 text-gray-300 !mt-2">
						Cégek és vállalkozások összekötése - mi vagyunk az összefüggés.
					</p>
				</div>
				<div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
					<div class="md:grid md:grid-cols-2 md:gap-8">
						<div>
							<h3 class="text-sm font-semibold leading-6 text-white">
								Népszerű kategóriák
							</h3>
							<ul role="list" class="mt-6 space-y-4">
								<li v-for="item in popularCategories.slice(0,4)" :key="item.name">
									<RouterLink :to="{ name: 'category', params: { category: item.slug } }"
										class="text-sm leading-6 text-gray-300 hover:text-white"
									>
										{{ item.name }}
									</RouterLink>
								</li>
							</ul>
						</div>
						<div class="mt-10 md:mt-0">
							<p class="hidden md:block text-sm font-semibold leading-6 text-white">&nbsp;</p>
							<ul role="list" class="mt-6 space-y-4">
								<li v-for="item in popularCategories.slice(4,8)" :key="item.name">
									<RouterLink :to="{ name: 'category', params: { category: item.slug } }"
										class="text-sm leading-6 text-gray-300 hover:text-white"
									>
										{{ item.name }}
									</RouterLink>
								</li>
							</ul>
						</div>
					</div>
					<div class="md:grid md:grid-cols-2 md:gap-8">
						<div>
							<p class="text-sm font-semibold leading-6 text-white">&nbsp;</p>
							<ul role="list" class="mt-6 space-y-4">
								<li v-for="item in popularCategories.slice(8, 12)" :key="item.name">
									<RouterLink :to="{ name: 'category', params: { category: item.slug } }"
										class="text-sm leading-6 text-gray-300 hover:text-white"
									>
										{{ item.name }}
									</RouterLink>
								</li>
							</ul>
						</div>
						<div class="hidden md:block mt-10 md:mt-0">
							<h3 class="text-sm font-semibold leading-6 text-white">
								Jogi információk
							</h3>
							<ul role="list" class="mt-6 space-y-4">
								<li v-for="item in legalCategories" :key="item.name">
									<RouterLink :to="{ name: item.href }"
										class="text-sm leading-6 text-gray-300 hover:text-white"
									>
										{{ item.name }}
									</RouterLink>
								</li>
							</ul>
						</div>
					</div>
					<div class="md:hidden">
						<div class="mt-10 md:mt-0">
							<h3 class="text-sm font-semibold leading-6 text-white">
								Jogi információk
							</h3>
							<ul role="list" class="mt-6 space-y-4">
								<li v-for="item in legalCategories" :key="item.name">
									<RouterLink :to="{ name: item.href }"
										class="text-sm leading-6 text-gray-300 hover:text-white"
									>
										{{ item.name }}
									</RouterLink>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 flex items-center">
				<a href="https://synet.hu" target="_blank">
					<img class="w-8 mr-6" src="@/assets/synet-logo.png" alt="Készítette Synet Digitális Technológiák Kft.">
				</a>
				<p class="text-xs leading-5 text-gray-400">
					&copy; {{ currentYear }} Vállalkozások.hu. Minden jog fenntartva.
				</p>
			</div>
		</div>
	</footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FooterLogo from '../svg/FooterLogo.vue'
import Category from '@/types/Category'

/**
 * The footer component
 */
export default defineComponent({
	components: {
		FooterLogo
	},
	data() {
		return {
			legalCategories: [
				{ name: 'ÁSZF', href: 'aszf' },
				{ name: 'Adatkezelési Tájékoztató', href: 'data-policy' },
				{ name: 'Kapcsolat', href: 'contact' },
				{ name: 'Süti Tájékoztató', href: 'cookie-policy' },
			]
		}
	},
	computed: {
		currentYear(): string {
			return (new Date()).getFullYear().toString()
		},
		popularCategories(): Category[] {
			const popularCategories = [...this.$store.state.categories]
			return popularCategories.sort((a: Category, b: Category) => {
				if (a.postCount === b.postCount) return 0

				return a.postCount > b.postCount
					? -1
					: 1
			})
		},
		categoriesLoaded(): boolean {
			return this.$store.state.categories.length > 0
		}
	}
})
</script>