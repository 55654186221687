<template>
	<header class="inset-x-0 top-0 z-40 fixed transition-all bg-white"
		:class="{ 'bg-white fixed shadow-md': fixed }">
		<nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
			<div class="flex lg:flex-1">
				<router-link class="-m-1.5 p-1.5" :to="{ name: 'home' }" aria-label="Logo">
					<HeaderLogo class="w-52" />
				</router-link>
			</div>
			<div class="flex lg:hidden">
				<button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
					@click="mobileMenuOpen = true">
					<span class="sr-only">Open main menu</span>
					<Bars3Icon class="h-6 w-6" aria-hidden="true" />
				</button>
			</div>
			<div class="hidden lg:flex lg:gap-x-12">
				<router-link v-for="item in navigation" :key="item.name" :to="item.href"
					class="text-sm font-semibold leading-6 text-gray-900">{{ item.name }}</router-link>
				<button @click="openSearchBox"
					class="text-sm font-semibold leading-6 text-gray-900 flex items-center cursor-pointer">
					<MagnifyingGlassIcon class="h-4 w-4 mr-2" /> Keresés
				</button>
			</div>
		</nav>
		<MobileMenu :navigation="navigation" :menu-state="mobileMenuOpen" 
			@close="closeMobileMenu()" @open-search-box="openSearchBox()" />
	</header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Bars3Icon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'

import HeaderLogo from '@/components/svg/HeaderLogo.vue'
import MobileMenu from './MobileMenu.vue'

/**
 * The header component 
 */
export default defineComponent({
	components: {
		Bars3Icon, MagnifyingGlassIcon, HeaderLogo, MobileMenu
	},
	data() {
		return {
			navigation: [
				{ name: 'Kezdőlap', href: { name: 'home' } }, 
				{ name: 'Kategóriák', href: { name: 'categories-all' } }, 
				{ name: 'Kapcsolat', href: { name: 'contact' }}
			],
			mobileMenuOpen: false,
			scrollTimer: 0 as any,
			scrollY: 0
		}
	},
	computed: {
		fixed(): boolean {
			return this.scrollY > 40
		}
	},
	methods: {
		closeMobileMenu(): void {
			this.mobileMenuOpen = false
		},
		handleScroll() {
			if (this.scrollTimer) return

			this.scrollTimer = setTimeout(() => {
				this.scrollY = window.scrollY
				clearTimeout(this.scrollTimer)
				this.scrollTimer = 0
			}, 100)
		},
		openSearchBox() {
			this.$store.commit('toggleSearchbox')
			/* this.$store.commit('toggleSearchBox') */

			/* if (this.mobileMenuOpen) {
				this.mobileMenuOpen = false
			} */
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	}
})
</script>