import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d229f7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "absolute w-full h-full grid place-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.skeleton.backgroundClass, _ctx.loaderClass], "relative overflow-hidden"])
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.shimmerStyle),
      class: "shimmer absolute top-0 right-0 bottom-0 left-0"
    }, null, 4),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}