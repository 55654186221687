import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowUpIcon = _resolveComponent("ArrowUpIcon")!

  return (_openBlock(), _createBlock(_Transition, { name: "scroll-top" }, {
    default: _withCtx(() => [
      (_ctx.buttonShown)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "btb fixed bottom-16 xl:bottom-8 right-10 z-50 bg-sky-900 rounded-xl p-3 shadow-sm grid place-items-center cursor-pointer transition-all active:scale-90 hover:bg-ch-dark-red-200",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToTop && _ctx.backToTop(...args)))
          }, [
            _createVNode(_component_ArrowUpIcon, { class: "h-6 w-6 text-white" })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}