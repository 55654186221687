import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageSkeletonLoader = _resolveComponent("ImageSkeletonLoader")!
  const _component_AdBox = _resolveComponent("AdBox")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(_ctx.classes),
    role: "presentation"
  }, [
    (!_ctx.adsLoaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (i) => {
          return _createElementVNode("li", { key: i }, [
            _createVNode(_component_ImageSkeletonLoader, { class: "w-full h-[150px] xl:h-[200px]" })
          ])
        }), 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.ads, (ad) => {
          return (_openBlock(), _createElementBlock("li", {
            key: ad.id
          }, [
            _createVNode(_component_AdBox, { ad: ad }, null, 8, ["ad"])
          ]))
        }), 128))
  ], 2))
}