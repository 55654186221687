import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed z-50 inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8"
}
const _hoisted_2 = { class: "max-w-4xl text-sm leading-6 text-gray-900" }
const _hoisted_3 = { class: "flex flex-none items-center gap-x-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.consent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode("Az oldalunk sütiket (cookie-kat) használ annak érdekében, hogy javítsa a böngészési élményt, személyre szabja a tartalmat, és elemzéseket készítsen a weboldal teljesítményéről. További információkat a "),
          _createVNode(_component_router_link, {
            to: { name: 'cookie-policy' },
            class: "font-semibold text-sky-600"
          }, {
            default: _withCtx(() => [
              _createTextVNode("süti tájékoztatóban")
            ]),
            _: 1
          }),
          _createTextVNode(" találsz.")
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveConsent('all'))),
            type: "button",
            class: "rounded-md bg-sky-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-900"
          }, "Összes elfogadása"),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveConsent('required'))),
            type: "button",
            class: "text-sm font-semibold leading-6 text-gray-900"
          }, "Szükséges sütik elfogadása")
        ])
      ]))
    : _createCommentVNode("", true)
}