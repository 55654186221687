<template>
	<section class="bg-white pt-12 pb-0 sm:py-6 relative">
		<div id="kategoriak" class="absolute w-0 h-0 sm:-top-32 -top-28"></div>
		<div class="mx-auto max-w-7xl px-4 lg:px-0">
			<div class="mx-auto max-w-2xl sm:text-center sm:mb-16 mb-12">
				<h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Kategóriák</h2>
				<p class="mt-6 text-lg leading-8 text-gray-600">A kategória menüpontban széles választékban találhatók különböző iparágak, szakmai területek és témák.</p>
			</div>

			<ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
				<template v-if="categoriesLoaded">
					<p v-if="popularCategories.length < 1" class="font-light text-sm mb-10 text-center">Még nincsenek elérhető kategóriák. Hamarosan visszatérünk új lehetőségekkel és tartalmakkal. </p>
					<CategoryItem v-else v-for="category in popularCategories.slice(0, 12)" :key="category.name" :category="category" />
				</template>
				<template v-else>
					<BaseSkeletonLoader v-for="i in 5" :key="i" class="w-full h-[58px]" />
				</template>
			</ul>

			<div class="mx-auto max-w-2xl sm:text-center sm:mb-0 sm:mt-6 my-8 mb-14">
				<router-link :to="{name: 'categories-all'}" class="text-sm font-semibold leading-6 text-sky-800 mx-auto">Összes kategória <span aria-hidden="true">→</span></router-link>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CategoryItem from '@/components/layout/CategoryItem.vue'
import BaseSkeletonLoader from '@/components/skeleton-loader/BaseSkeletonLoader.vue'
import type Category from '@/types/Category'


export default defineComponent({
	components: { 
		CategoryItem, BaseSkeletonLoader
	},
	data() {
		return {

		}
	},
	computed: {
		popularCategories(): Category[] {
			const popularCategories = [...this.$store.state.categories]
			return popularCategories.sort((a: Category, b: Category) => {
				if (a.postCount === b.postCount) return 0

				return a.postCount > b.postCount
					? -1
					: 1
			})
		},
		categoriesLoaded(): boolean {
			return this.$store.state.categories.length > 0
		}
	}
})
</script>