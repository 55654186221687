<template>
	<div>
		<div class="relative mt-6 mb-10 isolate">
			<svg class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]" aria-hidden="true">
				<defs>
					<pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
						<path d="M.5 200V.5H200" fill="none" />
					</pattern>
				</defs>
				<svg x="50%" y="-1" class="overflow-visible fill-gray-50">
					<path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
				</svg>
				<rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
			</svg>
			<div class="absolute top-0 right-0 -ml-24 overflow-hidden left-1/2 -z-10 transform-gpu blur-3xl lg:ml-24 xl:ml-48" aria-hidden="true">
				<div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)" />
			</div>
			<div class="overflow-hidden">
				<div class="px-6 pt-10 pb-8 mx-auto max-w-7xl sm:pt-20 lg:px-8 lg:pt-10">

					<div class="justify-center max-w-2xl mx-auto gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
						<div class="w-full max-w-2xl lg:shrink-0 xl:max-w-2xl">
							<h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Találd meg vállalkozásod még ma!</h1>
							<p class="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">A vállalkozások.hu egy dinamikus online platform, amely segít összehozni cégeket és szakembereket különböző szakmai területeken. Célunk elősegíteni az üzleti kapcsolatok és partnerségek kialakítását, hogy mind a vállalkozások, mind a szakemberek sikeresek lehessenek.</p>
							<div class="flex items-center mt-10 gap-x-6">
								<a href="#kereses" class="rounded-md bg-sky-600 px-3.5 py-2.5 pr-5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 flex"><MagnifyingGlassIcon class="w-4 mr-2 h4"></MagnifyingGlassIcon> Keresés</a>
								<a href="#kategoriak" class="text-sm font-semibold leading-6 text-gray-900">Kategóriák <span aria-hidden="true">→</span>
								</a>
							</div>
						</div>
						<div class="flex justify-end gap-8 mt-14 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
							<div class="flex-none pt-32 ml-auto space-y-8 w-44 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
								<a v-if="$store.getters.adsLoaded" target="_blank" :href="$store.state.ads[0].href" class="relative block" :aria-label="$store.state.ads[0].title">
									<img :src="$store.state.ads[0].imgPath" alt="" class="aspect-[1/1] w-full rounded-xl bg-gray-300 object-contain shadow-lg" />
									<div class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
								</a>
								<ImageSkeletonLoader v-else class="w-44 h-44" />
							</div>
							<div class="flex-none mr-auto space-y-8 w-44 sm:mr-0 sm:pt-52 lg:pt-36">
								<a v-if="$store.getters.adsLoaded" target="_blank" :href="$store.state.ads[1].href" class="relative block" :aria-label="$store.state.ads[1].title">
									<img :src="$store.state.ads[1].imgPath" alt="" class="aspect-[1/1] w-full rounded-xl bg-gray-300 object-contain shadow-lg" />
									<div class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
								</a>
								<ImageSkeletonLoader v-else class="w-44 h-44" />

								<a v-if="$store.getters.adsLoaded" target="_blank" :href="$store.state.ads[2].href" class="relative block" :aria-label="$store.state.ads[2].title">
									<img :src="$store.state.ads[2].imgPath" alt="" class="aspect-[1/1] w-full rounded-xl bg-gray-300 object-contain shadow-lg" />
									<div class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
								</a>
								<ImageSkeletonLoader v-else class="w-44 h-44" />
							</div>
							<div class="flex-none pt-32 space-y-8 w-44 sm:pt-0">
								<a v-if="$store.getters.adsLoaded" target="_blank" :href="$store.state.ads[3].href" class="relative block" :aria-label="$store.state.ads[3].title">
									<img :src="$store.state.ads[3].imgPath" alt="" class="aspect-[1/1] w-full rounded-xl bg-gray-300 object-contain shadow-lg" />
									<div class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
								</a>
								<ImageSkeletonLoader v-else class="w-44 h-44" />

								<a v-if="$store.getters.adsLoaded" target="_blank" :href="$store.state.ads[4].href" class="relative block" :aria-label="$store.state.ads[4].title">
									<img :src="$store.state.ads[4].imgPath" alt="" class="aspect-[1/1] w-full rounded-xl bg-gray-300 object-contain shadow-lg" />
									<div class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
								</a>
								<ImageSkeletonLoader v-else class="w-44 h-44" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
			
		<SearchComponent class="!pb-10" />

		<BaseContainer class="lg:px-0 !pb-0 sm:!pb-16 pt-0 sm:pt-4">
			<AdsGrid class-list="md:grid-cols-[repeat(auto-fit,150px)] xl:grid-cols-[repeat(auto-fit,180px)] gap-10 justify-center" />
		</BaseContainer>

		<CategoriesComponent />

		<BaseContainer class="lg:px-0 !pb-16">
			<BannerAd />
		</BaseContainer>

		<HighlightedContacts class="mb-36 sm:mb-24" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import ImageSkeletonLoader from '@/components/skeleton-loader/ImageSkeletonLoader.vue'
import SearchComponent from '@/components/layout/home/SearchComponent.vue'
import AdsGrid from '@/components/layout/AdsGrid.vue'
import CategoriesComponent from '@/components/layout/home/CategoriesComponent.vue'
import BannerAd from '@/components/layout/BannerAd.vue'
import HighlightedContacts from '@/components/layout/home/HighlightedContacts.vue'
import { useMeta } from 'vue-meta'


export default defineComponent({
	components: {
		MagnifyingGlassIcon, ImageSkeletonLoader, SearchComponent, AdsGrid, CategoriesComponent, BannerAd, HighlightedContacts
	},
	setup() {
		useMeta({
			title: 'Vállalkozások.hu - Az online vállalkozáskereső',
			meta: [
				{ name: 'keywords', content: 'kft, kereső, cégkereső, útvonaltervezés, útvonaltervező, cégtudakozó, cégkereső hu, ingyenes cégkereső, cégkereső budapest, cégkereső cím, országos cégtudakozó, térképes cégkereső, cégkereső cím alapján, vállalkozások, vállalkozások katalógusa, legjobb ajánlatok, szolgáltatók Magyarországon' }
			]
		})
	}
}) 
</script>