import axios, { AxiosResponse, AxiosResponseTransformer } from 'axios'
import { AxiosRequestConfig } from 'axios'
import { State } from 'vue'
import { ActionContext } from 'vuex'

import { Post, PostMeta } from '@/types/Post'
import Ad from '@/types/Ad'
import ApiResponse from '@/types/ApiResponse'
import Category from '@/types/Category'


/**
 * Ads fetching response transformer
 * Transforms request to Ad[] objects
 */

const adsResponseTransformer = (axios.defaults.transformResponse as AxiosResponseTransformer[]).concat((data: AxiosResponse<any>[]) => {
	const responseData: Ad[] = []
	
	data.forEach((element: any) => {
		responseData.push({
			id: element.id,
			imgPath: element?._embedded?.['wp:featuredmedia']?.[0]?.source_url || '',
			href: element?.metadata?.link?.[0] || '',
			title: element.title.rendered,
			excerpt: element?.metadata?.['banner-leiras']?.[0] || ''
		})
	});

	return responseData
})

/**
 * Transforms categories
 */
const categoryResponseTransformer = (axios.defaults.transformResponse as AxiosResponseTransformer[]).concat((data: AxiosResponse<any>[]) => {
	const responseData: Category[] = []

	data.forEach((element: any) => {
		responseData.push({
			id: element.id,
			name: element.name,
			slug: element.slug,
			postCount: element.count
		})
	});

	return responseData
})

/**
 * Response transformer to retrive post ids
 * @param data 
 * @returns 
 */
const searchResponseTransformer = (axios.defaults.transformResponse as AxiosResponseTransformer[]).concat((data: AxiosResponse<any>[]) => {
	const postIds: number[] = []

	data.forEach((post: any) => {
		postIds.push(post.id)
	})

	return postIds
})

/**
 * 
 * @param category 
 * @returns 
 */
const postResponseTransformer = (category: Category | undefined, categories: Category[] = []) => {
	return (axios.defaults.transformResponse as AxiosResponseTransformer[]).concat((data: AxiosResponse<any>[]) => {
		const responseData: Post[] = []
	
		data.forEach((element: any) => {
			const postTags: string[] = []
			let highlighted = false
	
			// Post tags
			element?._embedded?.['wp:term']?.[1]?.forEach((tag: any) => {
				if (tag.slug === 'kiemelt') {
					highlighted = true
					return
				}
	
				postTags.push(tag.name)
			})
	
			// Post meta
			const postMeta: PostMeta = {
				address: element.metadata?.cim?.[0] || undefined,
				phone: element.metadata?.telefon?.[0] || undefined,
				email: element.metadata?.email?.[0] || undefined,
				website: element.metadata?.weboldal?.[0] || undefined,
				link: element.metadata?.link?.[0] || undefined,
				googleLat: element.metadata?.['google-lat']?.[0] || undefined,
				googleLong: element.metadata?.['google-long']?.[0] || undefined
			}

			// Get post category
			if (category === undefined) {
				for (const postCategory of element.categories) {
					const temp = categories.find(
						(cat: Category) => cat.id === postCategory
					)

					if (temp) {
						category = temp
						break
					}
				}
			}
	
			// Create post
			responseData.push({
				id: element.id,
				title: element.title.rendered,
				excerpt: element.excerpt.rendered,
				content: element.content.rendered,
				category: category!,
				meta: postMeta,
				slug: element.slug,
				image: element?._embedded?.['wp:featuredmedia']?.[0]?.source_url || undefined,
				highlighted: highlighted,
				tags: postTags,
				date: new Date(element.date)
			})
		})
	
		return responseData
	})
}

/**
 * Creates a query parameter for wordpress REST API to get multiple posts by the given params
 * @param params post id
 * @returns query param
 */
const postQueryParamFactory = <T>(params: T[]) => {
	let finalString = '?'

	params.forEach((item: T) => {
		finalString += `include[]=${item}&`
	})

	return finalString.slice(0, -1)
}


/**
 * Store actions
 */
export default {
	loadAds: async (context: ActionContext<State, State>) => {
		const ad_category_id = 12
		const ads_per_page = 100

		const options: AxiosRequestConfig = {
			method: 'get',
			url: `/posts?categories=${ad_category_id}&per_page=${ads_per_page}&_embed`,
			transformResponse: adsResponseTransformer
		}

		try {
			const { status, data } = await axios<ApiResponse<Ad>>(options)

			if (status !== 200)
				throw new Error('Response error ' + status)

			context.commit('setAds', data)
		} catch (error) {
			console.error(error) // TODO: valami szebb hibakezelés
		}
	},
	loadBanners: async (context: ActionContext<State, State>) => {
		const ad_category_id = 11
		const ads_per_page = 100

		const options: AxiosRequestConfig = {
			method: 'get',
			url: `/posts?categories=${ad_category_id}&per_page=${ads_per_page}&_embed`,
			transformResponse: adsResponseTransformer
		}

		try {
			const { status, data } = await axios<ApiResponse<Ad>>(options)

			if (status !== 200)
				throw new Error('Response error ' + status)

			context.commit('setBanners', data)
		} catch (error) {
			console.error(error) // TODO: valami szebb hibakezelés
		}
	},
	loadCategories: async (context: ActionContext<State, State>) => {
		const parent_category_id = 9
		const categories_per_page = 100

		const options: AxiosRequestConfig = {
			method: 'get',
			url: `/categories?parent=${parent_category_id}&per_page=${categories_per_page}`,
			transformResponse: categoryResponseTransformer
		}

		try {
			const { status, data } = await axios<ApiResponse<Category>>(options)

			if (status !== 200)
				throw new Error('Response error ' + status)

			context.commit('setCategories', data)
		} catch (error) {
			console.error(error) // TODO: valami szebb hibakezelés
		}
	},
	loadPosts: async (context: ActionContext<State, State>, config: any) => {
		config = {
			perPage: 100,
			page: 1,
			...config
		}

		const options: AxiosRequestConfig = {
			method: 'get',
			url: `/posts?categories=${config._category.id}&_embed&per_page=${config.perPage}&page=${config.page}`,
			transformResponse: postResponseTransformer(config._category)
		}

		return axios<ApiResponse<Post>>(options)
	},
	loadPostDetail: async (context: ActionContext<State, State>, config: any) => {
		config = {
			category: undefined as Category | undefined,
			slug: undefined as string | undefined,
			...config
		}

		const options: AxiosRequestConfig = {
			method: 'get',
			url: `/posts?categories=${config.category.id}&_embed&slug=${config.slug}`,
			transformResponse: postResponseTransformer(config.category)
		}

		return axios<ApiResponse<Post>[]>(options)
	},
	loadHighlightedPosts: async (context: ActionContext<State, State>) => {
		const highlighted_tag = 13

		const options: AxiosRequestConfig = {
			method: 'get',
			url: 'posts',
			params: {
				tags: highlighted_tag,
				_embed: true
			},
			transformResponse: postResponseTransformer(undefined, context.state.categories)
		}

		return axios<ApiResponse<Post>[]>(options)
	},
	loadSearchResults: async (context: ActionContext<State, State>, config: any) => {
		const parent_category_id = 9

		config = {
			query: undefined as string | undefined,
			perPage: 100,
			page: 1,
			...config
		}

		const searchOptions: AxiosRequestConfig = {
			method: 'get',
			url: 'search',
			params: {
				search: config.query,
				/* per_page: config.perPage,
				page: config.page, */
				wpessid: true
			},
			transformResponse: searchResponseTransformer
		}

		let searchResponse
		try {
			searchResponse = await axios(searchOptions)

			if (searchResponse.data.length < 1)
				throw new Error('Empty resultset')
		} catch (err) {
			return new Promise((resolve) => {
				const errorResponse: ApiResponse<Post[]> = {
					status: 200,
					data: [],
					headers: { "x-wp-totalpages": 0, "x-wp-total": 0 }
				}

				resolve(errorResponse)
			})
		}

		const postOptions: AxiosRequestConfig = {
			method: 'get',
			url: `posts${postQueryParamFactory<number>(searchResponse.data)}`,
			params: {
				categories: parent_category_id,
				_embed: true,
				per_page: config.perPage,
				page: config.page,
			},
			transformResponse: postResponseTransformer(undefined, context.state.categories)
		}

		return axios<ApiResponse<Post>[]>(postOptions)
	}
}