<template>
	<TransitionRoot :show="searchboxState" as="template" @after-leave="query = ''" appear>
		<Dialog as="div" class="relative z-50" aria-label="Kereső kulcsszó alapján" @close="close">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
				leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
			</TransitionChild>
			<div class="fixed mt-10 inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
					enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
					leave-to="opacity-0 scale-95">
					<DialogPanel
						class="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
						<Combobox @update:modelValue="onSelect">
							<div class="relative">
								<MagnifyingGlassIcon
									class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
									aria-hidden="true" />
								<ComboboxInput
									class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
									placeholder="Keresés..." @change="query = $event.target.value" type="search"
									@keyup="$event.keyCode === 13 ? onSelect() : null" />
							</div>
							<div class="p-2">
								<h2 class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
									<span v-if="!displayError">A kereséshez nyomd meg az enter billentyűt.</span>
									<span v-else class="text-red-800">Kérlek adj meg több karaktert</span>
								</h2>
							</div>
						</Combobox>
					</DialogPanel>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import {
	Combobox,
	ComboboxInput,
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
} from "@headlessui/vue";

export default defineComponent({
	components: {
		MagnifyingGlassIcon,
		Combobox,
		ComboboxInput,
		Dialog,
		DialogPanel,
		TransitionChild,
		TransitionRoot,
	},
	data() {
		return {
			data: true,
			query: "",
		};
	},
	computed: {
		searchboxState(): boolean {
			return this.$store.state.searchboxState;
		},
		displayError(): boolean {
			return this.query === "" || this.query.length >= 3
				? false
				: true
		}
	},
	methods: {
		close(): void {
			this.$store.commit("toggleSearchbox");
		},
		onSelect(): void {
			if (this.query.trim().length < 3) return;

			this.close();

			this.$router.push({
				name: "search",
				query: {
					k: this.query,
				},
			});
		},
	},
});
</script>
