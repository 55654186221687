import { createApp } from 'vue'

import App from '@/App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'

import router from '@/router'
import store from '@/store'

import BaseContainer from './components/base/BaseContainer.vue'

// Array shuffle
import './array.extensions'

/**
 * Create application
 */
const app = createApp(App)

const metaManager = createMetaManager(false, { meta: { tag: 'meta', nameless: true }, })

const apiPath: string = process.env.VUE_APP_API_PATH || ''
const googleMapsApiKey: string = process.env.VUE_APP_MAPS_API || ''

/**
 * Mount components
 */
app.use(store)
app.use(router)
app.use(metaManager)
app.use(metaPlugin)
app.use(VueAxios, axios)
app.use(VueCookies, { expires: '30d' })

app.component('BaseContainer', BaseContainer)

/**
 * Config
 */
axios.defaults.baseURL = apiPath

app.config.globalProperties.$siteTitle = process.env.VUE_APP_TITLE || ''
app.config.globalProperties.$googleApi = googleMapsApiKey

/**
 * Mount app
 */
router.isReady().then(() => {
	app.mount('#app')
})