<template>
	<div :class="[skeleton.backgroundClass, loaderClass]"
		class="relative overflow-hidden">
		<div :style="shimmerStyle"
			class="shimmer absolute top-0 right-0 bottom-0 left-0">
		</div>

		<div v-if="$slots.default" class="absolute w-full h-full grid place-items-center">
			<!-- absolute left-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 grid place-items-center -->
			<slot></slot>
		</div>
	</div>
</template>

<script lang="ts">
import { PropType, StyleValue, defineComponent } from 'vue'

type Skeleton = {
	type?: string,
	backgroundClass?: string,
	cssClass?: string,
	shimmerColor?: string
}

/**
 * Basic Skeleton Loader
 */
export default defineComponent({
	props: {
		skeleton: {
			type: Object as PropType<Skeleton>,
			default: () => ({
				type: 'rectangle',
				backgroundClass: 'bg-gray-300',
				cssClass: '',
				shimmerColor: '#ffffff'
			})
		}
	},
	data() {
		return {}
	},
	computed: {
		shimmerStyle(): StyleValue {
			const rgb = this.isHexColor(this.skeleton.shimmerColor || '') ? this.hextToRgb(this.skeleton.shimmerColor || '') : '#ffffff'

			return {
				backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
			}
		},
		loaderClass(): string {
			return this.skeleton.cssClass ? this.skeleton.cssClass : 'rounded-lg'
		}
	},
	methods: {
		isHexColor(hexColor: string) {
			const hex = hexColor?.replace('#', '')

			return typeof hexColor === 'string' && hexColor.startsWith('#') && hex.length === 6 && !isNaN(Number('0x' + hex))
		},
		hextToRgb(hex: string) {
			return `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`
		}
	}
})
</script>

<style scoped>
.shimmer {
	transform: translateX(-100%);
	animation: shimmer 1.4s infinite;
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}
</style>