<template>
	<metainfo>
		<template v-slot:title="{ content }">{{ content }}</template>
	</metainfo>
	<div class="bg-white">
		<TheHeader />

		<main class="pt-20">
			<router-view v-slot="{ Component }">
				<transition name="router" mode="out-in">
					<component :key="new Date().toString()" :is="Component"></component>
				</transition>
			</router-view>

			<BackToTopButton />
			<CookieConsent />
			<SearchBox />
		</main>

		<TheFooter />
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import TheHeader from "./components/layout/TheHeader.vue"
import TheFooter from "./components/layout/TheFooter.vue"
import BackToTopButton from "./components/base/BackToTopButton.vue"
import CookieConsent from "./components/cookie-consent/CookieConsent.vue"
import SearchBox from "./components/layout/SearchBox.vue"

/**
 * App
 */
export default defineComponent({
	components: {
		TheHeader,
		TheFooter,
		BackToTopButton,
		CookieConsent,
		SearchBox,
	},
	created() {
		this.$store.dispatch("loadAds")
		this.$store.dispatch("loadBanners")
		this.$store.dispatch("loadCategories")
	},
})
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol", "Noto Color Emoji";
}

/* button:disabled {
	@apply disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
} */

input {
	@apply disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	appearance: textfield;
	-moz-appearance: textfield;
}

.spinning {
	animation-name: spin;
	animation-duration: 4000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
</style>

<style scoped>
.router-enter-active,
.router-leave-active {
	transition: all 200ms ease-out;
}

.router-enter-to {
	opacity: 1;
}

.router-enter-from {
	opacity: 0;
}

.router-leave-to {
	opacity: 0;
}

.router-leave-from {
	opacity: 1;
}
</style>
