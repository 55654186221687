import { State } from 'vue';
/**
 * Store getters
 */
export default {
	categoriesLoaded(state: State) {
		return state.categories.length > 0
	},
	adsLoaded(state: State) {
		return state.ads.length > 0
	},
	getSavedPost(state: State) {
		const post = {...state.saved}

		state.saved = undefined

		return post
	},
	isSavedPost(state: State) {
		return state.saved !== undefined
	}
}