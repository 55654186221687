<template>
	<a :href="ad.href" target="_blank">
		<img :src="ad.imgPath" :class="classes" class="object-contain bg-gray-300 min-h-[150px] xl:min-h-[180px]" :alt="ad.title">
	</a>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import Ad from '@/types/Ad'
import { twMerge } from 'tailwind-merge'

export default defineComponent({
	props: {
		ad: {
			type: Object as PropType<Ad>,
			required: true
		},
		classList: {
			type: String,
			required: false
		}
	},
	computed: {
		classes(): string {
			return twMerge('rounded-lg w-full shadow-md', this.classList)
		}
	}
})
</script>