<template>
	<BaseContainer id="kereses" class="bg-white lg:px-0">
		<div class="relative isolate overflow-hidden bg-gray-900 px-6 py-12 sm:py-16 text-center shadow-2xl sm:rounded-3xl sm:px-16">
			<div className="md:text-center">
				<h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl"> Keress <span
						className="relative whitespace-nowrap">
						<SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-400" />
						<span className="relative">egyszerűen és</span>
					</span> gyorsan. </h2>
				<p className="mt-4 text-lg text-slate-400"> A kereső funkció segítségével percek alatt megtalálhatod
					azokat a cégeket vagy szakembereket, amelyekre szükséged van. </p>
			</div>
			<div className="mx-auto max-w-7xl py-8 sm:px-6 sm:py-8 lg:px-8">
				<form @submit.prevent="goToSearch" class="mx-auto flex max-w-2xl gap-x-4">
					<input v-model="inputSearch" type="text" required="true" minlength="3"
						class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
						placeholder="Keresés vállalkozások között: nevek, tevékenységi területek, települések..." />
					<button type="submit"
						class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Keresés</button>
				</form>
			</div>
			<svg viewBox="0 0 1024 1024"
				class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
				aria-hidden="true">
				<circle cx="512" cy="512" r="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fill-opacity="0.7" />
				<defs>
					<radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
						<stop stop-color="#7775D6" />
						<stop offset="1" stop-color="#E935C1" />
					</radialGradient>
				</defs>
			</svg>
		</div>
	</BaseContainer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SwirlyDoodle from '@/components/svg/SwirlyDoodle.vue'

export default defineComponent({
	components: { 
		SwirlyDoodle 
	},
	params: {
		className: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			inputSearch: ''
		}
	},
	methods: {
		goToSearch() {
			if (this.inputSearch.trim().length >= 3) {
				this.$router.push({
					name: 'search',
					query: {
						k: this.inputSearch
					}
				})
			}
		}
	}
})
</script>