interface Array<T> {
	shuffle(): Array<T>
}

/**
 * Shuffles the array
 * Does not modify the original array
 */
Array.prototype.shuffle = function ()  {
	const array = [...this]
	let currentIndex = array.length, randomIndex

	while(currentIndex > 0) {
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex--

		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex], array[currentIndex]];
	}

	return array
}