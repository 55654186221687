<template>
	<li class="">
		<RouterLink :to="{ name: 'category', params: { category: category.slug } }" class="group col-span-1 flex rounded-md shadow-sm">
			<div :class="['bg-sky-700', 'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
				{{ getInitials(category.name) }}
			</div>
			<div
				class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
				<div class="flex-1 truncate px-4 py-2 text-sm">
					<p class="font-medium text-gray-900 group-hover:text-gray-600">
						{{ category.name }}
					</p>
					<p class="text-gray-500">{{ category.postCount }} hirdetés</p>
				</div>
				<div class="flex-shrink-0 pr-2">
					<button type="button"
						class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none ">
						<span class="sr-only">Hirdetések megtekintése</span>

						<p class="font-medium text-gray-900 group-hover:text-gray-600">
							<ArrowRightIcon class="h-5 w-5" aria-hidden="true" />
						</p>
					</button>
				</div>
			</div>
		</RouterLink>
	</li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PropType } from 'vue'
import { ArrowRightIcon } from '@heroicons/vue/24/outline'
import Category from '@/types/Category'


export default defineComponent({
	components: {
		ArrowRightIcon
	},
	props: {
		category: {
			type: Object as PropType<Category>,
			required: true
		}
	},
	methods: {
		getInitials(name: string): string {
			let acronym = name.split(/\s/).reduce(
				(response, word) => response += word.slice(0, 1)
			)

			return acronym.toUpperCase().substring(0, 2)
		}
	}
})
</script>